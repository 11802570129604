import React from "react";
import gigaNomicTextVector from "../../Assets/images/gigatonic-vector.svg";
import cyberScopeAudit from "../../Assets/images/cyberscope-audit.png";
import "./GigaNomics.css";
import ENSDomains from "../ENSDomains/ENSDomains";
import Mvps from "../Mvps/Mvps";
const GigaNomics = () => {
  return (
    <div className="giganomic-container" id="tokenomics">
      <div className="giganomics-details">
        <div className="giganomic-text">
          <h4>GigaNomic</h4>
          <img src={gigaNomicTextVector} alt="Text Vector" />
        </div>
        <div className="giganomic-main-details">
          <div className="giganomics-on-eth">
            <h4>Built on ETH Network</h4>
            <h5>Gigatalik contract address</h5>
            <h5 className="colored">ETH:</h5>
            <h5>0xcf0c1809c693d1e7baebe62b6a2e</h5>
            <div className="giganomic-button">
              <button>View on Etherscan</button>
            </div>
          </div>
          <div className="audited-text">
            <h4>Audited by</h4>
            <div>
              <img src={cyberScopeAudit} alt="" />
            </div>
          </div>
          <div className="giganomics-talik">
            <h4>GIGANOMICS For TALIK</h4>
            <h5 className="colored">Total Supply:</h5>
            <h5>420,000,000,000 TALIK</h5>
            <h5 className="colored">
              Fair Launch, No Private or Presale! <br />
              Zero 0% Tax.
            </h5>
            <p>
             No Taxes, No long story. It's that simple.
            </p>
            <p>
              LP tokens are burnt, and contract ownership is renounced
            </p>
            <div className="giganomic-button">
              <button>Buy Gigatalik</button>
            </div>
          </div>
        </div>
      </div>
      <ENSDomains />
      <Mvps/>
    </div>
  );
};

export default GigaNomics;
