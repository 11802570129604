import React from "react";
import gigaTalikLogo from "../../Assets/images/gigatalik-logo.png";
import "./Preloader.css";
const Preloader = () => {
  return (
    <div className="preloader">
      <div className="preloader-details">
        <div>
          <img src={gigaTalikLogo} alt="Preloader-logo" />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
