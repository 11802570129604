import { useEffect, useState } from "react";
import "./App.css";
import BuyGigatalik from "./Components/BuyGigatalik/BuyGigatalik";
import Header from "./Components/Header/Header";
import HeroPage from "./Components/HeroPage/HeroPage";
import NavBar from "./Components/NavBar/NavBar";
import HowToBuy from "./Components/HowToBuy/HowToBuy";
import BuyGiga from "./Components/BuyGiga/BuyGiga";
import GigaNomics from "./Components/GigaTonics/GigaNomics";
import NewsLetter from "./Components/NewsLetter/NewsLetter";
import Community from "./Components/Community/Community";
import Footer from "./Components/Footer/Footer";
import Preloader from "./Components/Preloader/Preloader";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Set a timer for 2 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    // Cleanup the timer
    return () => clearTimeout(timer);

    // setTimeout(() => {
    //   setOpen(true)
    // }, 1000);
  });
  return (
    <>
      {" "}
      {loading ? (
        <Preloader />
      ) : (
        <>
          <NavBar />
          <Header />
          <HeroPage />
          <BuyGigatalik />
          <div className="sticky-wrapper">
            <HowToBuy />
            <BuyGiga />
          </div>{" "}
          <GigaNomics />
          <NewsLetter />
          <Community />
          <Footer />
        </>
      )}{" "}
    </>
  );
}

export default App;
