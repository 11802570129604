import React, { useState } from "react";
import { Select } from "antd";
import gigaTalikMiniIllustration from "../../Assets/images/gigatalik-mini-illustration.png";
import gigaTalikMainIllustration from "../../Assets/images/gigatalik-main-illustration.png";
import "./HowToBuy.css";
import { message } from "antd";
import { AiFillCopy } from "react-icons/ai";
const HowToBuy = () => {
  const handleChange = (value) => {
    setContractAddress(value);
    console.log(`selected ${value}`);
  };
  const [contractAddress, setContractAddress] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const copyAlert = async () => {
    try {
      await window.navigator.clipboard.writeText(contractAddress);
      messageApi.success(`Copied ${contractAddress}`);
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      alert("Copy to clipboard failed.");
    }
  };
  return (
    <div className="how-to-buy-container" id="ecosystem">
      <div className="how-to-buy-details">
        <div className="how-to-buy-sub-container">
          <div className="contract-address">
            <Select
              defaultValue="Select Network"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                {
                  value: "0x225f48f62C135e984187c64737143854F8C01baB",
                  label: "Ethereum",
                },
                {
                  value: "43KkAwGPyyWMdxtbppA9ofL3bCBoQRzKiQxQr564f5Sy",
                  label: "Solana",
                },
                {
                  value: "0x26e8AD773541bd07Bf7f20bf4a20ab5C18B42B4A",
                  label: "BSC",
                },
                {
                  value: "0x225f48f62C135e984187c64737143854F8C01baB",
                  label: "Base",
                },
              ]}
            />
            <input
              type="text"
              value={
                contractAddress
                  ? `${contractAddress}`
                  : "0x225f48f62C135e984187c64737143854F8C01baB"
              }
              disabled
            />
            {contextHolder}

            <AiFillCopy className="copy-icon" onClick={copyAlert} />
          </div>
          <div className="how-to-buy-buttons">
            <div className="hero-page-button-primary">
              <button>Buy Now</button>
            </div>
            <div className="hero-page-button-secondary">
              <div>
                <button>View On Coingecko</button>
              </div>
            </div>
          </div>
        </div>

        <div className="why-gigatalik-details">
          <div className="why-gigatalik-main-text">
            <div className="why-gigatalik-mini-illustration">
              <img src={gigaTalikMiniIllustration} alt="" />
            </div>
            <div className="why-gigatalik-text">
              <h4>Why GIGATALIK? - Ecosystem</h4>
              <h5>
                We all have a <span>GigaTalik</span> in us. That inner voice of
                greatness in you. We’ve been preparing for this through the bear
                markets. Let’s take the bull market by storm in grand styles.
                <br />
                <br />
                The question is, do you own a <span>TALIK!</span>
              </h5>
            </div>
          </div>

          <div className="why-gigatalik-illustration">
            <img src={gigaTalikMainIllustration} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
