import React, { useState } from "react";
import { Carousel, Modal } from "antd";
import { isMobile } from "react-device-detect";
import { MVPs } from "./data";
import { MVPs2 } from "./data";
import "./Mvps.css";
const Mvps = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const showModal = (MVPs) => {
    if (isMobile) {
      setIsModalOpen(true);
      setModalContent([MVPs]);
      console.log("Clicked on Mobile");
    }
  };
  const handleMint = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="mvp-container">
      <div className="mvp-wrapper">
        <>
          <h4>MVPs</h4>
          <p>
            HODLing meme tokens should come with a value added feature. That’s
            why <span> GIGATALIK </span> is taking the bold steps in creating
            this meme-incentive token for its community and believers. <br />
            Check out both present and future plans <span>TALIK</span> is
            bringing to change the meme game. <br />
            You don’t wanna miss this!
            <br />
            And more to come !
          </p>
        </>
        <>
          <Carousel autoplay fade={true} arrows={true}>
            <div className="mvp-items">
              {MVPs.map((MVP) => (
                <div className="flip-card">
                  <div
                    className="flip-card-inner"
                    onClick={() => showModal(MVP)}
                  >
                    <div className="flip-card-front">
                      <div className="mvp-item">
                        <img src={MVP.mvpMainImage} alt="" />
                        <p>
                          {MVP.mvpMainName}
                          <span>{MVP.mvpSpanName}</span>
                        </p>
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <img src={MVP.mvpFlipImage} alt="" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Second Carousel */}

            <div className="mvp-items">
              {MVPs2.map((MVPsecond) => (
                <div className="flip-card">
                  <div
                    className="flip-card-inner"
                    onClick={() => showModal(MVPsecond)}
                  >
                    <div className="flip-card-front">
                      <div className="mvp-item">
                        <img src={MVPsecond.mvpMainImage} alt="" />
                        <p>
                          {MVPsecond.mvpMainName}
                          <span>{MVPsecond.mvpSpanName}</span>
                        </p>
                      </div>
                    </div>
                    <div class="flip-card-back">
                      <img src={MVPsecond.mvpFlipImage} alt="" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Carousel>
        </>
        {/* Modal Contents */}
        <Modal
          className="mvp-modal"
          open={isModalOpen}
          centered={true}
          footer={null}
          onCancel={handleCancel}
        >
          {modalContent.map((newMvpModal) => (
            <>
              <div className="modal-image">
                <img src={newMvpModal.mvpFlipImage} alt="" />
              </div>
            </>
          ))}
        </Modal>
      </div>
    </div>
  );
};

export default Mvps;
