import React from "react";
import { Carousel } from "antd";
import ensDomainsImage from "../../Assets/images/ens-domains-images.png";
import "./ENSDomains.css";
const ENSDomains = () => {
  return (
    <div className="ens-domains-container">
      <div className="ens-domains-details">
        <h4>Our Exclusive ENS Domains</h4>
      </div>
      <div className="ens-domains-wrapper">
      <Carousel autoplay autoplaySpeed={7000} arrows={true}>
        <div className="ens-domains-items">
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
        </div>
        <div className="ens-domains-items">
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
          <div className="ens-domains-item">
            <img src={ensDomainsImage} alt="" />
          </div>
        </div>
      </Carousel>
      </div>
    </div>
  );
};

export default ENSDomains;
