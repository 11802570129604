import walletItem from "../../Assets/images/wallet-item.png";
import botItem from "../../Assets/images/botItem.png";
import casinoItem from "../../Assets/images/casinoItem.png";
import aiItem from "../../Assets/images/aiItem.png";
import p2pItem from "../../Assets/images/p2pItem.png";
import mvpWalletFlipBack from "../../Assets/images/giga-wallet-flip-back.png";
import mvpStakeFlipBack from "../../Assets/images/giga-stake-flip-back.png";
import mvpBotFlipBack from "../../Assets/images/giga-bot-flip-back.png";
import mvpCasinoFlipBack from "../../Assets/images/giga-boom-casino-flip-back.png";
import mvpAIFlipBack from "../../Assets/images/giga-ai-flip-back.png";
import mvpVerseFlipBack from "../../Assets/images/giga-verse-marketplace-flip-back.png";
import mvpP2PFlipBack from "../../Assets/images/giga-p2p-flip-back.png";
import mvpPassFlipBack from "../../Assets/images/giga-pass-flip-back.png";
import passItem from "../../Assets/images/passItem.png";
import stakeItem from "../../Assets/images/stakeItem.png";
import gigaVerse from "../../Assets/images/gigaverseItem.png";

export const MVPs = [
  {
    key: 1,
    mvpMainName: "Giga",
    mvpSpanName: "STAKE",
    mvpMainImage: stakeItem,
    mvpFlipImage: mvpStakeFlipBack,
  },

  {
    key: 2,
    mvpMainName: "Giga",
    mvpSpanName: "WALLET",
    mvpMainImage: walletItem,
    mvpFlipImage: mvpWalletFlipBack,
  },

  {
    key: 3,
    mvpMainName: "Giga",
    mvpSpanName: "BOT",
    mvpMainImage: botItem,
    mvpFlipImage: mvpBotFlipBack,
  },
  {
    key: 4,
    mvpMainName: "Giga",
    mvpSpanName: "Boom Casino",
    mvpMainImage: casinoItem,
    mvpFlipImage: mvpCasinoFlipBack,
  },

  {
    key: 5,
    mvpMainName: "Giga",
    mvpSpanName: "AI",
    mvpMainImage: aiItem,
    mvpFlipImage: mvpAIFlipBack,
  },
  {
    key: 6,
    mvpMainName: "Giga",
    mvpSpanName: "Verse Marketplace",
    mvpMainImage: gigaVerse,
    mvpFlipImage: mvpVerseFlipBack,
  },
];

export const MVPs2 = [
  {
    key: 8,
    mvpMainName: "Giga",
    mvpSpanName: "P2P",
    mvpMainImage: p2pItem,
    mvpFlipImage: mvpP2PFlipBack,
  },
  {
    key: 9,
    mvpMainName: "Giga",
    mvpSpanName: "Pass",
    mvpMainImage: passItem,
    mvpFlipImage: mvpPassFlipBack,
  },
  {
    key: 10,
    mvpMainName: "Giga",
    mvpSpanName: "BOT",
    mvpMainImage: botItem,
    mvpFlipImage: mvpBotFlipBack,
  },
  {
    key: 11,
    mvpMainName: "Giga",
    mvpSpanName: "Boom Casino",
    mvpMainImage: casinoItem,
    mvpFlipImage: mvpCasinoFlipBack,
  },
  {
    key: 12,
    mvpMainName: "Giga",
    mvpSpanName: "AI",
    mvpMainImage: aiItem,
    mvpFlipImage: mvpAIFlipBack,
  },
  {
    key: 13,
    mvpMainName: "Giga",
    mvpSpanName: "Verse Marketplace",
    mvpMainImage: gigaVerse,
    mvpFlipImage: mvpVerseFlipBack,
  },
];
