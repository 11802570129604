import React from "react";
import "./HeroPage.css";
import gigaTalikLogo from "../../Assets/images/gigatalik-heropage.png";
const HeroPage = () => {
  return (
    <div className="hero-page-container">
      <div className="hero-page-first-details">
        <h5>
          It’s just a meme token with UTILITIES...” <br />a fun filled community
          centric meme token, built for Chads and Alphas.
        </h5>
      </div>
      <div className="hero-page-second-details">
        <div className="hero-page-main-details">
          <img
            src={gigaTalikLogo}
            alt="gigaTalik Heropage Logo"
            className="hero-page-illustration"
          />
          <div className="hero-page-main-text">
            <span>By the community for the community</span>
            <h4>
              Let's Go Bullish with
              <br />
              Gigatalk Meme Coin.
            </h4>
            <h5>
              <span>GIGATALIK:</span> a meme token with dynamic utilities,
              <br />
              Probably Something More Bullish for the <span>BullRun</span>.
            </h5>
            <div className="hero-page-buttons">
              <div className="hero-page-button-primary">
                <button>Buy Now</button>
              </div>
              <div className="hero-page-button">
                <div>
                  <button>Explore More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
