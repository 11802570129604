import React from "react";
import gigaTalikLogo from "../../Assets/images/gigatalik-logo.png";
import footerVector from "../../Assets/images/footer-vector.png";
import "./Footer.css";
const Footer = () => {
  return (
    <>
      <img src={footerVector} alt="" className="footer-vector" />
      <div className="footer-container">
        <div className="footer-details">
          <div className="footer-navigation">
            <div>
              <a href="">
                <img src={gigaTalikLogo} alt="" />
              </a>
            </div>
            <div>
              <h5>Products</h5>
              <div className="nav-list">
                <ul>
                  <li>GigaWALLET</li>
                  <li>GigaSTAKE</li>
                  <li>GigaBOT</li>
                  <li>GigaBoom Casino</li>
                  <li>GigaVerse Marketplace</li>
                  <li>GigaAi</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p>
          RISK WARNING: Trading Cryptocurrencies is highly speculative, carries
          a level of risk and may not be suitable for all investors. You may
          lose some or all of your invested capital, therefore you should not
          speculate with capital that you cannot afford to lose. The content on
          this site should not be considered investment advice. Investing is
          speculative. When investing your capital is at risk. The project has
          the right to change the conditions based on community-centric
          principles and voting rules.
          <br />  <br />
          LEGAL DISCLAIMER: $TALIK is a community-centric meme coin with no
          intrinsic value or expectation of financial return and is for
          entertainment purposes only. Trading crypto, especially memecoins,
          involves significant risk and potential capital loss. Memecoins can be
          extremely volatile. Conduct thorough research. When you trade or
          purchase $TALIK, you are agreeing that you have seen this disclaimer.
          But probably something BULLISH.
          <br />  <br />
          The information on this site is not intended for person in any country
          or jurisdiction where such distribution or use would be contrary to
          local law or regulation. Any opinions, news, research, analyses,
          prices, or other information contained on this website including
          internal and external links are provided as general ​market
          commentary, and do not constitute investment or financial advice.
          Trading cryptocurrencies carries a high level of risk, and may not be
          suitable for all investors. ​Before deciding to trade cryptocurrency
          you should carefully consider your investment objectives, level of
          experience, and risk appetite. $TALIK is not liable for your personal
          financial decisions.
          <br />  <br />
        </p>
        <p>© 2024 by Gigatalik. All rights reserved</p>
      </div>
    </>
  );
};

export default Footer;
