import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { Drawer } from "antd";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { FaBars } from "react-icons/fa";
import gigaTalikLogo from "../../Assets/images/gigatalik-logo.png";
const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className={isSticky ? "navbar-container, sticky" : "navbar-container"}>
      <div className="navbar-details">
        <div className="logo">
          <a href="##">
            <img src={gigaTalikLogo} alt="GigaTalik Logo" />
          </a>
        </div>
        <div className="nav-links">
          <ul>
            <li>
              <AnchorLink href="#ecosystem">
                <div>
                  <span>Eco System</span>
                </div>
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#how-to-buy">
                <div>
                  {" "}
                  <span>How to Buy</span>
                </div>
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#tokenomics">
                <div>
                  {" "}
                  <span>Tokenomics</span>
                </div>
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#community">
                <div>
                  <span>Community</span>
                </div>
              </AnchorLink>
            </li>
          </ul>
        </div>
        <div className="nav-button">
          <button>Buy Now</button>
        </div>
        <div className="mobile-toggle">
          <FaBars className="toggler" onClick={showDrawer} />
        </div>
      </div>
      <Drawer onClose={onClose} open={open}>
        <div className="mobile-nav-list">
          <ul>
            <li onClick={onClose}>
              <AnchorLink href="#ecosystem">Eco System </AnchorLink>
            </li>
            <li onClick={onClose}>
              <AnchorLink href="#how-to-buy">How to Buy </AnchorLink>
            </li>
            <li onClick={onClose}>
              <AnchorLink href="#tokenomics">Tokenomics </AnchorLink>
            </li>
            <li onClick={onClose}>
              <AnchorLink href="#community">Community </AnchorLink>
            </li>
          </ul>
          <div className="mobile-nav-button">
            <button>Buy Now</button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default NavBar;
