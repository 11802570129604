import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Buffer } from "buffer";
import process from "process";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (!window.Browser) {
  window.Browser = {};
}
if (!window.Browser.T) {
  window.Browser.T = () => {
    console.warn("Browser.T function is missing. Using polyfill.");
  };
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
