import React from "react";
import newsLetterVector from "../../Assets/images/newsletter-vector.png";
import geckoTerminalLogo from "../../Assets/images/gecko-terminal.png";
import xLogo from "../../Assets/images/twitter-main-icon.png";
import telegramLogo from "../../Assets/images/telegram-icon.png";
import "./NewsLetter.css";
const NewsLetter = () => {
  return (
    <div className="news-letter-container">
      <div className="news-letter-details">
        <div className="news-letter-text-socials">
          <div className="news-letter-social-text">
            <h4>
              Why is <span>GIGATALIK</span> community-centric based? Because
              nothing great is built alone..
              <br />
              <span>Follow our socials</span>
            </h4>
          </div>
          <div className="news-letter-socials">
            <div>
              <a href="##">
                <img src={geckoTerminalLogo} alt="" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/Gigatalik"
              >
                <img src={xLogo} alt="" />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://t.me/gigatalik">
                <img src={telegramLogo} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="news-letter-text">
          <img src={newsLetterVector} alt="" />
          <h4>Newsletter</h4>
        </div>
        <div className="youtube-iframe">
          <iframe
            src="https://www.youtube.com/embed/bRJw4ekgmxs?si=pEvg-ODvq42-lDFo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
