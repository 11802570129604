import React, { useState, useEffect } from "react";
import "./Header.css";
import Marquee from "react-fast-marquee";
import geckoTerminalIcon from "../../Assets/images/gecko-terminal.png";
import twitterIcon from "../../Assets/images/twitter-main-icon.png";
import telegramIcon from "../../Assets/images/telegram-icon.png";
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <div
      className={
        isSticky ? "header-container, sticky-header" : "header-container"
      }
    >
      <div className="header-details">
        <>
          <Marquee>
            <div className="marquee-container">
              <div className="header-market-cap">
                <h5>
                  <span>Market Cap:</span> $45,4547 46748
                </h5>
              </div>
              <div className="header-volume">
                <h5>
                  <span>VOLUME (24h):</span> $45,4547 46748
                </h5>
              </div>
            </div>
          </Marquee>
        </>
        <div className="header-socials">
          <div className="header-socials-icon">
            <a href="##">
              <img src={geckoTerminalIcon} alt="" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://x.com/Gigatalik">
              <img src={twitterIcon} alt="" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://t.me/gigatalik">
              <img src={telegramIcon} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
