import React from "react";
import communityIllustration from "../../Assets/images/community-illustration.svg";
import communityTwitterIcon from "../../Assets/images/twitter-community-logo.png";
import telegramIcon from "../../Assets/images/telegram-community-logo.png";
import communityTextVector from "../../Assets/images/community-vector.png";
import "./Community.css";
const Community = () => {
  const handleNavigateTwitter = () => {
    window.open("https://x.com/Gigatalik", "_blank", "noopener,noreferrer");
  };
  const handleNavigateTelegram = () => {
    window.open("https://t.me/gigatalik", "_blank", "noopener,noreferrer");
  };
  return (
    <div className="community-container" id="community">
      <div className="community-details">
        <div className="community-text">
          <div className="community-main-text">
            <div>
              <h5>
                The Force, the Notion, the Ambition and Ability to take your
                chance and make a Change is <span>EVERYTHING.</span> <br />{" "}
                <br />
                <span>Join the Force today!</span>
              </h5>
              <img src={communityTextVector} alt="" />
            </div>
          </div>
          <div className="hero-page-button-primary">
            <button className="community-btn" onClick={handleNavigateTwitter}>
              <img src={communityTwitterIcon} alt="" />
              Follow On Twitter
            </button>
          </div>
          <div className="hero-page-button-primary">
            <button className="community-btn" onClick={handleNavigateTelegram}>
              {" "}
              <img src={telegramIcon} alt="" />
              Join Telegram
            </button>
          </div>
        </div>
        <div className="community-illustration">
          <img src={communityIllustration} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Community;
