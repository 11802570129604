import React, { useState } from "react";
import { Select } from "antd";
import "./BuyGiga.css";
import gigaTalikMiniIllustration from "../../Assets/images/gigatalik-mini-illustration.png";
import gigaTalikMainIllustration from "../../Assets/images/gigatalik-main-illustration.png";
import item1Illustration from "../../Assets/images/item-1-illustration.png";
import item2Illustration from "../../Assets/images/item-2-illustration.png";
import item3Illustration from "../../Assets/images/item-3-illustration.png";
import item4Illustration from "../../Assets/images/item-4-illustration.png";
import { AiFillCopy } from "react-icons/ai";
import { message } from "antd";

const BuyGiga = () => {
  const [contractAddress, setContractAddress] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const handleChange = (value) => {
    setContractAddress(value);
    console.log(`selected ${value}`);
  };
  const copyAlert = async () => {
    try {
      await window.navigator.clipboard.writeText(contractAddress);
      messageApi.success(`Copied ${contractAddress}`);
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      alert("Copy to clipboard failed.");
    }
  };

  return (
    <div className="how-to-buy-container" id="how-to-buy">
      <div className="how-to-buy-details">
        <div className="how-to-buy-sub-container">
          <div className="contract-address">
            <Select
              defaultValue="Select Network"
              style={{ width: 120 }}
              onChange={handleChange}
              options={[
                {
                  value: "0x225f48f62C135e984187c64737143854F8C01baB",
                  label: "Ethereum",
                },
                {
                  value: "43KkAwGPyyWMdxtbppA9ofL3bCBoQRzKiQxQr564f5Sy",
                  label: "Solana",
                },
                {
                  value: "0x26e8AD773541bd07Bf7f20bf4a20ab5C18B42B4A",
                  label: "BSC",
                },
                {
                  value: "0x225f48f62C135e984187c64737143854F8C01baB",
                  label: "Base",
                },
              ]}
            />
            <input
              type="text"
              value={
                contractAddress
                  ? `${contractAddress}`
                  : "0x225f48f62C135e984187c64737143854F8C01baB"
              }
              disabled
            />
            {contextHolder}

            <AiFillCopy className="copy-icon" onClick={copyAlert} />
          </div>
          <div className="how-to-buy-buttons">
            <div className="hero-page-button-primary">
              <button>Buy Now</button>
            </div>
            <div className="hero-page-button-secondary">
              <div>
                <button>View On Coingecko</button>
              </div>
            </div>
          </div>
        </div>

        <div className="why-gigatalik-details">
          <div className="how-to-buy-full-details">
            <div className="how-to-buy-major-detail">
              <img src={gigaTalikMiniIllustration} alt="" />
              <h4>How To Buy Gigatalik Token</h4>
            </div>
            <div className="how-to-buy-step-details">
              <div className="how-to-buy-step-detail">
                <img src={item1Illustration} alt="" />
                <div className="how-to-buy-main-header">
                  <h4>CREATE A WALLET</h4>
                  <p>
                    Download Metamask, TrustWallet or your wallet of choice from
                    the Apple App Store or Google Play store for free. Desktop
                    users, download the google chrome extension by going to
                    metamask.io.
                  </p>
                </div>
              </div>
              <div className="how-to-buy-step-detail">
                <img src={item2Illustration} alt="" />
                <div className="how-to-buy-main-header">
                  <h4>GET SOME ETH.</h4>
                  <p>
                    Fund your wallet with ETH before swapping to $TALIK. If you
                    don’t have any ETH, you can buy directly on Uniswap or Flooz
                    with your credit card below or with Metamask or TrustWallet
                    transfer from another wallet, or buy on another exchange and
                    send it to your wallet
                  </p>
                </div>
              </div>
              <div className="how-to-buy-step-detail">
                <img src={item4Illustration} alt="" />
                <div className="how-to-buy-main-header">
                  <h4>GO TO UNISWAP.</h4>
                  <p>
                    Visit app.uniswap.org in Google Chrome or on the browser
                    inside your Metamask mobile app. Connect your wallet. Paste
                    the $TALIK token address into Uniswap, select the amount of
                    ETH you want to swap for $TALIK, and confirm. When Metamask
                    prompts you for a wallet signature, sign the transaction.
                    $TALIK Token Address: 0xaa23aadb7afa2810263653a34ba2c21c7a
                  </p>
                </div>
              </div>
              <div className="how-to-buy-step-detail">
                <img src={item3Illustration} alt="" />
                <div className="how-to-buy-main-header">
                  <h4>SWAP ETH FOR $TALIK</h4>
                  <p>
                    Click the Swap button to buy $TALIK. We have zero taxes so
                    you don’t need to worry about buying with a specific
                    slippage, although you may need to use slippage during times
                    of high market volatility.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="why-gigatalik-illustration">
            <img src={gigaTalikMainIllustration} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyGiga;
